import axios from "axios";
import { getUserInfo } from "@/util/userInfo";
import { getOS } from "@/util/os";

//外包-正式
const header = "https://yunicu-api.yunicu.com";

// 外包替换(正式)
const headerYUN = "https://application.yunicu.com";
// 外包替换测试
// const headerYUN = "http://admin-dev-api.yunicu.com";
//外包测试
//  const header = "http://yunicu-api.we-fire.cn";
// let { token, user_id } = getUserInfo("token,user_id");
let Os = getOS("Os");
let token = "";
let user_id = "";
if (window.localStorage.getItem("user_info") != 'undefined' && window.localStorage.getItem("user_info")) {
  token = JSON.parse(window.localStorage.getItem("user_info")).id;
  user_id = JSON.parse(window.localStorage.getItem("user_info")).id;
}else{
  window.localStorage.removeItem('user_info') // 如果user_info为undefined则清除
}

// console.log(user_id);

//下载表格----params.ExelName为表格名字
export function getExel(url, params) {
  return axios
    .get(header + url, {
      params,
      responseType: "blob",
    })
    .then((res) => {
      if (!res) return;
      let blob = new Blob([res.data], {
        type: "application/vnd.ms-excel;charset=utf8",
      });
      let downloadElement = document.createElement("a");
      let href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.href = href;
      downloadElement.download = params.ExelName; //下载后文件名
      // downloadElement.download =decodeURIComponent(
      //     res.headers['content-disposition'].split(";")[1].split("filename=")[1]); // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    })
    .catch((e) => {
      console.log(e);
    });
}

export function get(url, params) {
  return axios
    .get(header + url, {
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function getMp(url, params) {
  return axios
    .get(header + url, {
      params,
      headers: { "x-token": token, "x-client-tag": "web_mac" },
      // withCredentials: true,
    })
    .then((res) => {
      const cookies = res.headers["set-cookie"]; // 从document对象中获取当前页面的Cookie值
      console.log("Cookies: ", cookies);
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function getBanner(url, params) {
  return axios
    .get("https://application.yunicu.com" + url, {
      params,
      headers: { "x-token": token },
      // withCredentials: true,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function postMp(url, params) {
  return axios
    .post(header + url, params, {
      headers: { "x-token": token, "x-client-tag": "web" },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function post(url, params, headers2) {
  return axios
    .post(header + url, params, { headers: { ...headers2 } })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function putXtoken(url, params, headers2) {
  return axios
    .put(header + url, params, {
      headers: { headers2, "x-token": token, "x-client-tag": "web_mac" },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

export function put(url, params) {
  return axios
    .put(header + url, params)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function del(url, params) {
  return axios
    .delete(header + url, params)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

//点播
// post
export function postVideo(url, params, headers2) {
  return axios
    .post(header + url, params, {
      headers: {
        "x-token": token,
        "x-client-tag": Os,
        ...headers2,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

//get
export function getVideo(url, params, headers2) {
  return axios
    .get(header + url, {
      params,
      headers: {
        // "MemberId": user_id,
        member_id: user_id,
        // memberId: user_id,
        // "userid":user_id,
        "x-token": token,
        "x-client-tag": Os,
        ...headers2,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
//del
export function delVideo(url, params, headers2) {
  return axios
    .delete(header + url, {
      data: params,
      headers: { "x-token": token, "x-client-tag": Os, ...headers2 },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

// 重写

export function getYUN(url, params) {
  return axios
    .get(headerYUN + url, {
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
export function postYUN(url, params) {
  return axios
    .post(headerYUN + url, params, { headers: { channel: "web", os: Os } })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}
//封装get
export function getYUNByid(url, params) {
  return axios
    .get(headerYUN + url, {
      params,
      headers: { byId: user_id },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
}